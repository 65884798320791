<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="app-container">
    <header :class="headerClass">
      <div class="header-left">
        <img src="@/assets/logo_white.png" alt="Skyhost Logo" class="logo" />
        <!-- update style to hide underline -->
        <router-link style="text-decoration: none" to="/home">
          <span class="header-title">S K Y H O S T</span>
        </router-link>
      </div>
      <nav class="header-right">
        <ul class="nav-links">
          <li v-if="isUserAuthenticated">
            <router-link to="/how-it-works">How it Works</router-link>
          </li>
          <li v-if="isUserAuthenticated">
            <button @click="logout" class="logout-button">
              <vue-feather
                style="width: 15px; height: 15px"
                type="log-out"
              ></vue-feather>
            </button>
          </li>
          <li v-else>
            <button @click="login" class="login-button">Login</button>
          </li>
        </ul>
      </nav>
    </header>

    <!-- Streaming Setup Instructions -->
    <div :class="mainContentClass">
      <div class="title-container">
        <!-- add a video player here -->
        <video
          id="video"
          width="100%"
          height="100%"
          controls="false"
          loop
          autoplay
          muted="false"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/skyhost-181eb.appspot.com/o/demo.MP4?alt=media&token=457d95bf-539c-4f7e-b89c-95f06218b013"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <h1 class="main-title">Drone Streaming, Made Easy</h1>
        <div class="chip">Low latency</div>
        <div class="chip">Clean feed</div>
        <div class="chip">No extra hardware</div>
        <div class="chip">Audio call support</div>
        <br />
        <br />
      </div>

      <div class="step-container">
        <p class="step-description">
          Live streaming your drone footage can be frustrating, especially with
          delays, complex setups, or distracting on-screen overlays cluttering
          the view. But it doesn't have to be that way.
          <br />
          <br />
          Skyhost is designed to make live streaming easier, cleaner, and more
          reliable, so you can focus on what really matters—sharing your flight
          experiences in real time, without the hassle. Whether you're doing a
          virtual tour or conducting an inspection, we’re here to help you make
          the process seamless and engaging for your viewers.
        </p>
        <br />
        <br />
      </div>

      <div class="step-container">
        <h2 class="step-title">Preflight Checklist</h2>
        <p class="step-description">
          <vue-feather
            type="check-circle"
            size="12"
            style="color: green; margin-right: 0.5rem"
          />
          <b>Check if your drone is compatible:</b><br />
        </p>
        <div class="step-container">
          <table class="drone-model-table">
            <tbody>
              <tr
                v-for="(droneRow, rowIndex) in chunkArray(
                  sortedDrones,
                  numberOfColumns
                )"
                :key="rowIndex"
              >
                <td v-for="(drone, index) in droneRow" :key="index">
                  {{ drone }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p class="step-description">
          <vue-feather
            type="check-circle"
            size="12"
            style="color: green; margin-right: 0.5rem"
          />
          <b>Ensure a stable internet connection:</b><br />
          Before starting your live stream, it's important to have a stable
          internet connection. This applies to the device running the DJI Fly
          app or the internet connection your DJI controller is using. To
          quickly check your connection speed, simply go to Google, type "speed
          test," and check the speed (specifically, the upload speed).
          <br />
          <br />
        </p>
        <p class="step-item-label">
          Recommended Upload speed: <code>>10 Mbps</code>
        </p>
        <img src="@/assets/speed_test.png" class="banner" alt="" />
      </div>
      <br />
      <div class="step-container">
        <a class="step-item-label">Step 1</a>
        <h2 class="step-title">Create a New Flight</h2>
        <p class="step-description">
          <a
            href="https://skyhost.ai/login"
            target="_blank"
            style="color: #1565c0"
            >Log into Skyhost</a
          >, and create a new flight meeting by filling out the flight details.
          Be sure to allow microphone access after submitting so you can
          communicate with your viewers during the live stream. Also, make a
          note of the RTMP address, as it will be used in the next step.
        </p>
        <img
          class="banner"
          src="@/assets/banner_flight_portal.png"
          alt="Flight Portal"
        />
      </div>
      <br />
      <div class="step-container">
        <a class="step-item-label">Step 2</a>
        <h2 class="step-title">Start the Live Stream</h2>
        <p class="step-description">
          <b>Start Your Drone:</b>
          <br />
          Switch on your drone as you normally would and ensure the video feed
          is active in the DJI Fly App.
          <br />
          <br />
          <b>Set Streaming Settings:</b><br />
          Locate the Livestream settings in DJI Fly App or DJI RC Controller and
          configure the following:
        </p>
        <ul class="step-list">
          <br />
          <li class="step-item-label">
            <b>RTMP Address: </b><code>Copy From Step 1</code>
          </li>
          <li class="step-item-label">
            <b>Livestream Resolution: </b><code>1080p</code>
          </li>
          <li class="step-item-label">
            <b>Livestream Bitrate: </b> <code>5Mbps</code>
          </li>
          <li>
            <img
              src="@/assets/dji_stream_settings.png"
              class="banner"
              alt="Streaming Settings"
            />
          </li>
        </ul>
        <br />
        <br />
        <p class="step-description">
          <b>Start Streaming:</b><br />
          Keep your laptop in front of you and ensure you've joined the new
          flight meeting. Press OK and wait for the stream to start. If you
          experience any quality issues, try with lower resolution and bitrate
          or check your internet connection (upload speed).
        </p>
      </div>
      <br />
      <div class="step-container">
        <a class="step-item-label">Step 3</a>
        <h2 class="step-title">Share the Streaming Link</h2>
        <p class="step-description">
          After you’ve started streaming, you can share the link with your
          audience. Your viewers can join the live stream in real time from
          anywhere.
        </p>
        <img src="@/assets/livestream_flight.png" class="banner" alt="" />
      </div>
      <br />
      <br />
      <div class="step-container">
        <p class="step-description">
          Streaming your drone footage in real-time with Skyhost is a simple and
          effective way to share stunning aerial views with an audience, no
          matter where they are in the world. Whether you're showcasing
          beautiful landscapes, conducting inspections, or giving a virtual
          tour, Skyhost allows you to do it seamlessly. We’re excited to see
          what you create—get started today and bring your drone flights to the
          world!
        </p>
      </div>

      <p class="date">20th September, 2024</p>
      <div class="divider" />

      <div class="footer-container">
        <p>
          To stay up to date on the latest about Skyhost, follow us on
          Instagram(
          <a
            href="https://www.instagram.com/_skyhost_/"
            style="color: #1565c0"
            target="_blank"
            >@_skyhost_</a
          >)
        </p>
        <div class="copyright">
          <p>
            &copy; 2024,
            <a
              href="http://www.imahima.com/en/"
              target="_blank"
              style="color: #1565c0"
              >Tinker Pte. Ltd.</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/plugins/firebase";
import { signOut } from "firebase/auth";
import { useSessionStore } from "../stores/session.js";
import { isMobile } from "@/utils/helper";
import { computed } from "vue";
import { amplitude } from "../plugins/amplitude";

export default {
  data() {
    return {
      flights: [],
      drones: [
        "DJI Neo",
        "DJI Air 3",
        "DJI Mini 4 Pro",
        "DJI Mavic 3 Classic",
        "DJI Mavic 3 Pro",
        "DJI Inspire 3",
        "DJI Matrice 350 RTK",
        "DJI Mini 3 Pro",
        "DJI Avata 1/2",
        "DJI Mini 3",
        "DJI Air 2S",
        "DJI FPV",
        "DJI Mavic 3",
        "DJI Mavic 2 Pro",
        "DJI Mavic 2 Zoom",
        "DJI Phantom 4 Pro V2.0",
        "DJI Mavic Air 2",
        "DJI Matrice 300 RTK",
        "DJI Matrice 600 Pro",
        "DJI Phantom 4 RTK",
        "DJI Mavic Mini",
        "DJI Matrice 200 Series V2",
        "DJI Inspire 2",
        "DJI Matrice 200 Series",
        "DJI Phantom 4 Pro",
        "DJI Matrice 100",
        "DJI Inspire 1",
        "DJI Phantom 3",
      ],
    };
  },
  computed: {
    isUserAuthenticated() {
      const sessionStore = useSessionStore();
      return sessionStore.userId !== null;
    },
    sortedDrones() {
      return this.drones;
    },
    numberOfColumns() {
      return isMobile() ? 2 : 4;
    },
  },
  methods: {
    chunkArray(arr, chunkSize) {
      let chunks = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
      }
      return chunks;
    },
    login() {
      amplitude.track("Login from Blog", {
        type: "Blog to Login",
      });
      this.$router.push("/login");
    },
    logout() {
      signOut(auth)
        .then(() => {
          const sessionStore = useSessionStore();
          sessionStore.clearUser();
          this.$router.push("/");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
  mounted() {
    const sessionStore = useSessionStore();
    auth.onAuthStateChanged((user) => {
      if (user) {
        sessionStore.userId = user.uid;
      } else {
        sessionStore.userId = null;
      }
    });
  },
  setup() {
    const mainContentClass = computed(() => {
      return isMobile() ? "mobile-main-content" : "web-main-content";
    });
    const headerClass = computed(() => {
      return isMobile() ? "mobile-header" : "web-header";
    });

    return {
      mainContentClass,
      headerClass,
    };
  },
};
</script>

<style scoped>
.step-list {
  list-style-type: none;
  padding-left: 0.5rem;
  margin-bottom: -1rem;
}

.step-list-item {
  margin-bottom: 1rem;
}

.step-item-label {
  font-weight: bold;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 1rem;
}

code {
  background-color: #2d2d2d;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #fff;
}
/* Container and layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.web-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  background-color: #000;
  border-bottom: 1px solid #2d2d2d;
}

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  background-color: #000;
  border-bottom: 1px solid #2d2d2d;
}

.drone-model-table {
  width: 100%;
  border-collapse: collapse;
}

.drone-model-table th,
.drone-model-table td {
  border: 1px solid #2d2d2d;
  padding: 8px;
  text-align: left;
  font-size: 10px;
  font-weight: bold;
  color: #a8a8a8;
}

.drone-model-table th {
  background-color: #2d2d2d;
  color: white;
}

.header-left {
  display: flex;
  align-items: center;
}

.logo {
  height: 25px;
  margin-right: 1rem;
}

.header-title {
  color: white;
  font-size: 18px;
  font-weight: 100;
  list-style-type: none;
}

.header-right {
  display: flex;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-links a,
.nav-links button {
  color: white;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  background: none;
  border: none;
}

/* Main Content */
.mobile-main-content {
  margin-top: 20px;
  padding: 20px;
}

.web-main-content {
  padding: 2rem 22rem;
}

.banner {
  width: 100%;
  height: auto;
  border-radius: 6px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title-container {
  margin-bottom: 1rem;
  border-bottom: 1px solid #2d2d2d;
}

.divider {
  border-bottom: 1px solid #2d2d2d;
}

.main-title {
  font-size: 5rem;
}

.chip {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.8rem;
  color: white;
  background-color: #2d2d2d;
  border-radius: 12px;
  margin-top: 1rem;
  margin-right: 5px;
}

.date {
  font-size: 1rem;
  color: gray;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.step-container {
  margin-bottom: 1rem;
  margin-top: 0.2rem;
}

.step-title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1rem;
}

.step-description {
  font-size: 1rem;
  line-height: 1.6;
  color: #dcdcdc;
}

.login-button {
  font-size: 14px;
  background-color: #1565c0 !important;
  width: 80px;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  height: 45px;
}

.logout-button {
  background-color: #2d2d2d !important;
  border: none;
  border-radius: 12px;
  color: white;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.logout-button:hover {
  background-color: #464646 !important;
}

.copyright {
  padding: 1rem;
  margin-top: 2rem;
  align-items: center;
  width: 100%;
  color: #a8a8a8;
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
}

.footer-container {
  margin-top: 10rem;
}
</style>
